<template>
  <v-container fluid class="pa-0">
    <v-card>
      <v-card-title>
        Editar usuario

        <v-spacer></v-spacer>
      </v-card-title>

      <v-breadcrumbs :items="routes">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :to="item.href" :disabled="item.disabled">
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </v-card>

    <v-container fluid>
      <div v-if="user_loading">
        <v-col>
          <v-skeleton-loader class="mx-auto" type="table"></v-skeleton-loader>
        </v-col>
      </div>

      <div v-else>
        <div v-if="user_error != null">
          <v-col>
            <v-alert text outlined color="deep-orange" icon="mdi-fire">
              {{ user_error }}
            </v-alert>
          </v-col>
        </div>

        <div v-else>
          <v-form @submit.prevent="saveUser">
            <v-row>
              <v-col cols="12" md="8">
                <v-card>
                  <v-card-title>
                    Información
                    <v-spacer></v-spacer>
                  </v-card-title>

                  <v-card-text>
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="user_data.username"
                          :error-messages="UserDataUsernameErrors"
                          clearable
                          label="Usuario"
                          @input="$v.user_data.username.$touch()"
                          @blur="$v.user_data.username.$touch()"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="user_data.password"
                          :type="password_show ? 'text' : 'password'"
                          :append-icon="password_show ? 'mdi-eye' : 'mdi-eye-off'"
                          :error-messages="UserDataPasswordErrors"
                          label="Contraseña"
                          @click:append="password_show = !password_show"
                          @input="$v.user_data.password.$touch()"
                          @blur="$v.user_data.password.$touch()"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-combobox
                          v-model="user_data.role"
                          :items="roles"
                          :error-messages="UserDataRoleErrors"
                          clearable
                          label="Selecciona un rol"
                          @input="$v.user_data.role.$touch()"
                          @blur="$v.user_data.role.$touch()"
                        ></v-combobox>
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="user_data.count"
                          :error-messages="UserDataCountErrors"
                          clearable
                          label="Cantidad"
                          @keypress="isNumber($event)"
                          @input="$v.user_data.count.$touch()"
                          @blur="$v.user_data.count.$touch()"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" md="4">
                <v-card>
                  <v-card-title>
                    Acciones disponibles
                    <v-spacer></v-spacer>
                  </v-card-title>

                  <v-card-text> Por favor selecciona una opción </v-card-text>

                  <v-card-actions>
                    <v-btn color="error" @click="closeForm()">
                      <v-icon>mdi-close</v-icon>
                      Cerrar
                    </v-btn>
                    <v-btn color="primary" type="submit">
                      <v-icon>mdi-save</v-icon>
                      Guardar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </div>
    </v-container>
  </v-container>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  validations: {
    user_data: {
      username: { required },
      password: { required },
      role: { required },
      count: { required },
    },
  },
  name: "UserView",
  components: {},
  data: () => ({
    // Navigation
    routes: [
      {
        text: "Usuarios",
        disabled: false,
        href: "/user/index",
      },
      {
        text: "Ver",
        disabled: true,
        href: "/user/view",
      },
    ],
    // Form Data
    roles: ["admin", "usuario"],
    password_show: false,

    user_loading: true,
    user_data: null,
    user_error: null,
  }),
  mounted() {
    this.valityUser();
    this.getUser();
  },
  methods: {
    valityUser() {
      let role = this.$store.getters.getUser.role;

      if (role != "admin") this.$router.push("/register/index");
    },
    getUser() {
      this.user_loading = true;

      this.$axios
        .get(
          "https://fiel.compratec.com.mx/backend/public/api/user/" +
            this.$route.params.id
        )
        .then((response) => {
          this.user_data = response.data;

          if (this.users_data === "") {
            this.user_error =
              "El usuario no existe, por favor de ingresar un identificador correcto.";
          }
        })
        .catch((error) => {
          console.log(error.response.data);
        })
        .finally(() => (this.user_loading = false));
    },
    async saveUser() {
      this.$v.user_data.$touch();

      if (!this.$v.user_data.$invalid) {
        this.user_loading = true;

        this.$axios
          .put(
            "https://fiel.compratec.com.mx/backend/public/api/user/" +
              this.$route.params.id,
            this.user_data
          )
          .then((response) => {
            this.closeForm();
          })
          .catch((error) => {
            console.log(error.response.data);
          })
          .finally(() => (this.user_loading = false));
      }
    },
    closeForm() {
      this.$router.push("/user/index");
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
        this.user_data.count = 0;
      } else {
        return true;
      }
    },
  },
  computed: {
    UserDataUsernameErrors() {
      const errors = [];
      if (!this.$v.user_data.username.$dirty) return errors;
      !this.$v.user_data.username.required &&
        errors.push("Usuario es requerido");
      return errors;
    },
    UserDataPasswordErrors() {
      const errors = [];
      if (!this.$v.user_data.password.$dirty) return errors;
      !this.$v.user_data.password.required &&
        errors.push("Contraseña es requerido");
      return errors;
    },
    UserDataRoleErrors() {
      const errors = [];
      if (!this.$v.user_data.role.$dirty) return errors;
      !this.$v.user_data.role.required && errors.push("Rol es requerido");
      return errors;
    },
    UserDataCountErrors() {
      const errors = [];
      if (!this.$v.user_data.count.$dirty) return errors;
      !this.$v.user_data.count.required && errors.push("Cantidad es requerido");
      return errors;
    },
  },
};
</script>

